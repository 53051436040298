import React from "react"
import PersonalLoan from "../../components/body/pages/personal-loan"
import Layout from "../../components/layout"
import SEO from "../../components/seo"

const PersonalLoanPage = () => (
  <Layout>
    <SEO
      canonical={"https://kuda.com/en-ng/personal-loan/"}     
      title="Kuda Loan App: Borrow Money in Nigeria | Fast Payment"
      description="Personal Loans with the Kuda App in Nigeria  | Fast Payment"
    />
    <PersonalLoan />
  </Layout>
)

export default PersonalLoanPage
